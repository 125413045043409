import classNames from 'classnames'
import { ElementType } from 'react'
import { Box } from '../Box'
import { CardProps } from './types'

const defaultElement = 'div'

const Card = <C extends ElementType = typeof defaultElement>({
  children,
  className,
  as,
  border = true,
  ...props
}: CardProps<C>) => {
  const element: React.ElementType = as || defaultElement

  return (
    <Box
      as={element}
      className={classNames(
        'basis-full flex flex-col rounded-sm overflow-hidden bg-white transition-colors duration-200 ease-in-out',
        border && 'border border-border-default',
        element === 'a' &&
          'border border-white hover:border hover:border-border-default',
        className,
      )}
      {...props}
    >
      {children}
    </Box>
  )
}

export { Card }
