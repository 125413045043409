import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { forwardRef } from 'react'
import { Popover } from '../Popover'
import { Content as PopoverContent } from '../Popover/Content'
import { Portal } from '../Portal'
import { DrawerContentProps } from './types'
import { Transition } from '../Transition'

export const Content: React.FC<DrawerContentProps> = forwardRef<
  HTMLDivElement,
  DrawerContentProps
>(
  (
    {
      children,
      className,
      direction,
      transition,
      dismissDragOffset = 100,
      onAnimationEnd,
      ...props
    },
    ref,
  ) => {
    const positionClass = direction === 'left' ? 'left-0' : 'right-0'

    return (
      <Portal>
        <Popover.Backdrop className="z-50" />
        <Transition.Slide
          direction={direction}
          onAnimationEnd={onAnimationEnd}
          initial
          className={twMerge(
            'fixed top-0 bottom-0 z-[60] w-full max-w-[496px] flex justify-center overflow-hidden pointer-events-none contain-layout',
            positionClass,
            className,
          )}
          role="complementary"
          style={{
            transition,
          }}
          keepMounted
        >
          <PopoverContent
            className={classNames(
              'flex flex-col bg-white w-full pointer-events-auto relative',
            )}
            ref={ref}
            {...props}
          >
            {children}
          </PopoverContent>
        </Transition.Slide>
      </Portal>
    )
  },
)

Content.displayName = 'ActionSheetContent'
