import { FC, HTMLAttributes } from 'react'

import { CloseIconButton } from '../CloseIconButton'
import { Popover } from '../Popover'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

export const Header: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <header
      className={twMerge(
        'relative flex items-center justify-between px-6 py-6 border-b',
        className,
      )}
      {...props}
    >
      {children}
      <Popover.Trigger>
        <CloseIconButton
          className="!bg-transparent button--icon-full !h-4 !w-4"
          aria-label={t('action.close')}
        />
      </Popover.Trigger>
    </header>
  )
}
