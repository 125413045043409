import classNames from 'classnames'

import { SearchProps } from './types'

export const Overlay = ({ isFull, ...props }: SearchProps) => {
  const { children } = props

  return (
    <div
      className={classNames(
        'w-full',
        'h-full',
        'bg-white',
        'py-8',
        '2xl:py-10',
      )}
      {...props}
    >
      {children}
    </div>
  )
}
