import { useScroll } from 'framer-motion'
import { useEffect, useState } from 'react'

export const useScrollAnimation = (startY = 100) => {
  const { scrollY } = useScroll()
  const [hidden, setHidden] = useState(false)

  useEffect(
    () =>
      scrollY.on('change', () => {
        const curr = scrollY?.get() || 0
        const prev = scrollY?.getPrevious() || 0
        setHidden(curr > prev && !(curr < startY))
      }),
    [],
  )

  return {
    variants: {
      show: { y: 0 },
      hide: { y: '-100%' },
    },
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 250,
    },
    animate: hidden ? 'hide' : 'show',
  }
}
