import classNames from 'classnames'
import { ElementType } from 'react'
import { Box } from '../Box'
import type { BodyProps } from './types'

const defaultElement = 'div'

export const Body = <TElement extends ElementType = typeof defaultElement>({
  as,
  children,
  className,
}: BodyProps<TElement>): JSX.Element => {
  const element: React.ElementType = as || defaultElement

  return (
    <div onPointerDownCapture={(evt) => evt.stopPropagation()}>
      <Box
        as={element}
        className={classNames('px-6 py-6 overflow-y-auto', className)}
      >
        {children}
      </Box>
    </div>
  )
}
